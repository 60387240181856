import {
	faCheck,
	faExclamation,
	faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React from 'react';
import {useToaster, toast as toastFunc} from 'react-hot-toast';

import styles from './style.module.scss';

const Toast = () => {
	const {toasts, handlers} = useToaster({duration: 5000});
	const {startPause, endPause} = handlers;
	const toast = toasts[0];
	return toast ? (
		<div
			className={styles.toast}
			onMouseEnter={startPause}
			onMouseLeave={endPause}>
			<div
				key={toast.id}
				className={clsx(
					styles.toastBar,
					toast.visible ? styles.in : styles.out,
				)}
				style={{
					opacity: toast.visible ? 1 : 0,
				}}>
				<div className={clsx(styles.leftBar, styles[toast.type])}>
					<FontAwesomeIcon
						icon={toast.type === 'success' ? faCheck : faExclamation}
					/>
				</div>
				<div className={clsx(styles.message, styles[toast.type])}>
					{toast.message}
				</div>
				<div
					className={clsx(styles.closeButton, 'touchable-opacity')}
					onClick={() => toastFunc.dismiss()}>
					<FontAwesomeIcon icon={faTimes} />
				</div>
			</div>
		</div>
	) : null;
};

export default Toast;
