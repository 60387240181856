import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import get from 'lodash/get';

import {version} from '../../package.json';

class BugsnagClass {
	constructor() {
		this.bugsnagEnabled =
      process.env.NODE_ENV !== 'test' && process.env.REACT_APP_BUGSNAG_APIKEY;
		this.initialize();
	}
	setBugsnagObjectMetadata(report, errObj) {
		Object.keys(errObj).forEach(key => {
			report.addMetadata('metadata', key, errObj[key]);
		});
	}
	initialize() {
		if (this.bugsnagEnabled) {
			this.Bugsnag = Bugsnag.start({
				apiKey: process.env.REACT_APP_BUGSNAG_APIKEY,
				autoBreadcrumbs: true,
				plugins: [new BugsnagPluginReact()],
				releaseStage: process.env.REACT_APP_RELEASE_STAGE,
				appVersion: version,
			});
		}
	}

	error(err) {
		if (this.Bugsnag) {
			Bugsnag.notify(err, report => {
				if (get(err, 'response.config')) {
					const {headers, method, params, data, url} = err.response.config;
					this.setBugsnagObjectMetadata(report, {
						headers: JSON.stringify(headers),
						params: JSON.stringify(params),
						requestData: JSON.stringify(data),
						responseData: JSON.stringify(err.response.data),
						status: err.response.status,
						method: method,
						url: url,
					});
				} else if (err.graphql) {
					this.setBugsnagObjectMetadata(report, err.graphql);
				} else {
					report.addMetadata('metadata', 'json', err);
				}
			});
		}
	}
}

export default new BugsnagClass();