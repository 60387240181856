import firebase from 'firebase/app';

import * as fbq from 'utils/fbPixel';

import packageJson from '../../package.json';

class Analytics {
	constructor() {
		this.defaultProperties = {
			appVersion: packageJson.version,
			timestamp: `${new Date().getTime()}`,
		};
		this.buildTrack = process.env.REACT_APP_RELEASE_STAGE;
		this.analyticsEnabled = this.buildTrack === 'production';
	}

	initializeAnalytics() {
		if (this.analyticsEnabled) {
			this.Analytics = firebase.analytics();
			this.setUserProperties();
		}
	}

	setUserProperties() {
		if (this.analyticsEnabled) {
			this.Analytics.setUserProperties(this.defaultProperties);
		}
	}

	logEvent(eventName, param = {}) {
		if (this.Analytics && this.analyticsEnabled) {
			try {
				const eventParam = {...this.defaultProperties, ...param};
				this.Analytics.logEvent(eventName, eventParam);
				fbq.trackCustom(eventName, param);
			} catch (e) {
				console.log(e);
			}
		}
	}

	pageview() {
		if (this.Analytics && this.analyticsEnabled) {
			this.Analytics.logEvent('page_view', this.defaultProperties);
		}
		fbq.pageview();
	}
}

const analytics = new Analytics();

export default analytics;
