export const LOCAL_STORAGE_NAMES = {
	ORIGIN: 'origin',
	REDIRECT_URL: 'redirectUrl',
	RESEND_OTP_BLOCKED_UNTIL: 'resendOtpBlockedUntil',
	TEMP_CALLER_PREFIX: 'tempCallerPrefix',
	SIGN_UP_WITH_REFERRAL: 'signUpWithReferral',
};

export const COOKIE_NAMES = {
	TOKENS: 'hangryTokens',
	TEMP_OTP_TOKEN: 'tempOtpToken',
	CAN_SHOW_OTHER_METHOD: 'canShowOtherMethod',
	PIN_BLOCKED: 'pinBlocked',
	OTP_BLOCKED: 'otpSubmitBlocked',
	REQUEST_OTP_LIMIT: 'otpRequestLimited',
};

export const ERROR_CODES = {
	RESEND_OTP_LIMIT: 'MAG-103',
	PIN_LIMIT_REACHED: 'MAG-106',
	PIN_INCORRECT: 'MAG-107',
};

export const CHANNEL_OTHERS = 'Lainnya';
export const ATTRIBUTION_CHANNEL = [
	'GrabFood', 'GoFood', 'ShopeeFood', 'Teman / Kerabat', 'Social Media (Instagram, Youtube)', CHANNEL_OTHERS,
];