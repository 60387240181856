import base64 from 'base-64';
import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';

import history from 'utils/history';
import {COOKIE_NAMES, LOCAL_STORAGE_NAMES} from './constants';

import PAGE_CONSTANTS from 'assets/styles/_hangry-constants.module.scss';

// Formatting phone number
export const convertToPhone4digit = (str = '') => {
	return str.length > 0 && !isEmpty(str)
		? str.toString().replace(/\B(?=(\d{4})+(?!\d))/g, '-')
		: '';
};

export const formatDisplayPhone = (prefix, str) => {
	const prefixRegex = new RegExp(`^[${prefix}0]+`);
	const clearNumberPrefix = str.replace(/\D/g, '').replace(prefixRegex, '');
	const displayNumber = convertToPhone4digit(clearNumberPrefix);
	return {
		raw: clearNumberPrefix,
		display: displayNumber,
	};
};

export const initializeAuth = () => {
	if (history.location.pathname !== '/logout') {
		if (document.referrer) {
			const urlParams = new URLSearchParams(window.location.search);
			const origin = urlParams.get('origin');
			const redirectUrl = urlParams.get('next');
			if (origin && redirectUrl) {
				localStorage.setItem(LOCAL_STORAGE_NAMES.ORIGIN, origin);
				localStorage.setItem(LOCAL_STORAGE_NAMES.REDIRECT_URL, redirectUrl);

				const tokens = Cookies.getJSON(COOKIE_NAMES.TOKENS);
				if (tokens) {
					redirectToSource();
				}
			} else {
				window.history.forward();
			}
		} else {
			window.location.href = 'https://ishangry.com';
		}
	}
};

export const generateUrlString = (route = '', query = {}) => {
	const queryCollection = [];
	Object.keys(query).forEach(key => {
		queryCollection.push(`${key}=${query[key]}`);
	});
	if (typeof query === 'object' && Object.keys(query).length > 0) {
		return `${route}?${queryCollection.join('&')}`;
	}
	return route;
};

export const afterVerified = (
	rawTokens,
	isRegistration = false,
	withReferral,
) => {
	const tokens = rawTokens.reduce(
		(previousValue, currentValue) => ({
			...previousValue,
			[currentValue.service]: currentValue.token,
		}),
		{},
	);
	Cookies.set(COOKIE_NAMES.TOKENS, tokens, {expires: 30});
	if (withReferral) {
		localStorage.setItem(LOCAL_STORAGE_NAMES.SIGN_UP_WITH_REFERRAL, true);
	}
	if (isRegistration) {
		history.replace('/createPin');
	} else {
		redirectToSource();
	}
};

export const redirectToSource = () => {
	const tokens = Cookies.get(COOKIE_NAMES.TOKENS);
	const withReferral = localStorage.getItem(
		LOCAL_STORAGE_NAMES.SIGN_UP_WITH_REFERRAL,
	);
	const origin = localStorage.getItem(LOCAL_STORAGE_NAMES.ORIGIN);
	const redirectUrl = localStorage.getItem(LOCAL_STORAGE_NAMES.REDIRECT_URL);

	const encodedTokens = base64.encode(tokens);

	let newUrl = new URL(redirectUrl);
	if (window.opener) {
		window.opener.postMessage(
			JSON.stringify({
				access_token: encodedTokens,
				signUpWithReferral: !!withReferral,
				nextUrl: redirectUrl,
			}),
			origin,
		);
		window.close();
	} else {
		let newUrlParams = newUrl.searchParams;
		newUrlParams.append('access_token', encodedTokens);
		newUrlParams.append('signUpWithReferral', !!withReferral);
		newUrlParams.append('nextUrl', redirectUrl);
		newUrl.search = newUrlParams.toString();
		window.location.replace(newUrl);
	}
	localStorage.removeItem(LOCAL_STORAGE_NAMES.SIGN_UP_WITH_REFERRAL);
	localStorage.removeItem(LOCAL_STORAGE_NAMES.REDIRECT_URL);
};

export const scaleDesignWidth = (pxValue, includePxUnit = true) => {
	const parsedPxValue = parseInt(pxValue);
	const designMobileWidth = parseInt(PAGE_CONSTANTS.designMobileWidth);

	const deviceSize = (parsedPxValue * window.innerWidth) / designMobileWidth;

	if (parsedPxValue < 0) {
		const maxResult = Math.max(deviceSize, parsedPxValue);
		return includePxUnit ? `${maxResult}px` : maxResult;
	}
	const minResult = Math.min(deviceSize, parsedPxValue);
	return includePxUnit ? `${minResult}px` : minResult;
};
