import firebase from 'firebase/app';
import 'firebase/analytics';

class Firebase {
	constructor() {
		try {
			const firebaseConfig = {
				apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
				authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
				databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
				projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
				storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
				messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
				appId: process.env.REACT_APP_FIREBASE_APP_ID,
				measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
			};
			process.env.REACT_APP_RELEASE_STAGE === 'production' &&
        firebase.initializeApp(firebaseConfig);
		} catch (error) {
			console.log(error);
		}
	}
}

export default new Firebase();
