import React from 'react';

import Loadable from 'react-loadable';
import PulseLoader from 'components/PulseLoader';

const Loading = () => {
	return (
		<div className="loading-loadable">
			<PulseLoader color="#ffffff" />
		</div>
	);
};

export const PhoneNumberInput = Loadable({
	loader: () => import('views/PhoneNumberInput'),
	loading() {
		return <Loading />;
	},
});

export const PinVerification = Loadable({
	loader: () => import('views/PinVerification'),
	loading() {
		return <Loading />;
	},
});

export const SmsOtp = Loadable({
	loader: () => import('views/SmsOtp'),
	loading() {
		return <Loading />;
	},
});

export const CallOtp = Loadable({
	loader: () => import('views/CallOtp'),
	loading() {
		return <Loading />;
	},
});

export const Registration = Loadable({
	loader: () => import('views/Registration'),
	loading() {
		return <Loading />;
	},
});

export const CreatePin = Loadable({
	loader: () => import('views/CreatePin'),
	loading() {
		return <Loading />;
	},
});

export const CreatePinConfirmation = Loadable({
	loader: () => import('views/CreatePinConfirmation'),
	loading() {
		return <Loading />;
	},
});
export const Logout = Loadable({
	loader: () => import('views/Logout'),
	loading() {
		return <Loading />;
	},
});
