import Toast from 'components/Toast';
import React, {useEffect, useState} from 'react';
import {Router, Route, Redirect, Switch} from 'react-router-dom';

import 'utils/firebase';
import analytics from 'utils/analytics';
import history from 'utils/history';
import {initializeAuth} from 'utils/utils';
import {initializeFacebookPixel} from 'utils/fbPixel';

import './App.scss';
import {
	CallOtp,
	CreatePin,
	CreatePinConfirmation,
	Logout,
	PhoneNumberInput,
	PinVerification,
	Registration,
	SmsOtp,
} from './loadables';


function App() {
	const [location, setLocation] = useState(history.location);

	useEffect(() => {
		initializeAuth();
		analytics.initializeAnalytics();
		initializeFacebookPixel();
		analytics.pageview();
		history.listen(location => {
			analytics.pageview();
			setLocation(location);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="App">
			<Toast />
			<Router history={history}>
				<Switch location={location}>
					<Route
						exact
						path="/">
						<PhoneNumberInput />
					</Route>
					<Route
						exact
						path="/pin">
						<PinVerification />
					</Route>
					<Route
						exact
						path="/smsotp">
						<SmsOtp />
					</Route>
					<Route
						exact
						path="/callotp">
						<CallOtp />
					</Route>
					<Route
						exact
						path="/registration">
						<Registration />
					</Route>
					<Route
						exact
						path="/createpin">
						<CreatePin />
					</Route>
					<Route
						exact
						path="/createpinconfirmation">
						<CreatePinConfirmation />
					</Route>
					<Route
						exact
						path="/logout">
						<Logout />
					</Route>
					<Route path="*">
						<Redirect to="/" />
					</Route>
				</Switch>
			</Router>
		</div>
	);
}

export default App;
